import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, List, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				FieldGoals Arena
			</title>
			<meta name={"description"} content={"Ваш головний напрямок для футбольних розваг!"} />
			<meta property={"og:title"} content={"FieldGoals Arena"} />
			<meta property={"og:description"} content={"Ваш головний напрямок для футбольних розваг!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header1 />
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(90deg,rgba(0,0,0,.2) 0%,rgba(0, 0, 0, 0.85) 100%),--color-dark url(https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11:45:26.787Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" font="18px/27px --fontFamily-googleBebasNeue" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="30px"
					text-transform="uppercase"
					font="--headline2"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Ваш головний напрямок для футбольних розваг
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				color="--orange"
				text-transform="uppercase"
			>
				Ласкаво просимо на FieldGoals Arena!
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Ласкаво просимо на FieldGoals Arena, де ваша пристрасть до футболу знаходить ідеальне поле! Незалежно від того, чи хочете ви провести товариський матч, підготуватися до майбутнього сезону чи відсвяткувати особливу подію, наші футбольні поля вищого рівня розроблені, щоб забезпечити виняткові враження від гри. Пориньте у світ футболу разом з нами та зробіть кожну гру незабутньою.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			overflow-y="hidden"
			overflow-x="hidden"
			quarkly-title="Advantages/Features-12"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					box-shadow="--xl"
					lg-height="auto"
				>
					<Image
						height="589px"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						box-shadow="--xxl"
						src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"
						object-fit="cover"
						lg-height="auto"
						lg-display="block"
						srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11%3A45%3A26.777Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 20px 0px"
				sm-margin="32px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="36px 0px 16px 0px"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Про FieldGoals Arena
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="--lead"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--light"
							font="--lead"
							lg-max-width="720px"
						>
							FieldGoals Arena пропонує оренду футбольних полів преміум-класу для гравців усіх рівнів. Наші найсучасніші приміщення ретельно обслуговуються відповідно до професійних стандартів, забезпечуючи вам оптимальні умови для гри. Від юнацьких ліг до дорослих турнірів, наші поля обслуговують широкий спектр футбольних подій. Виберіть FieldGoals, щоб отримати неперевершену якість і сервіс у світі футболу.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px" />
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px" />
				</List>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 50% 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Огляд послуг
			</Text>
			<Image
				src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12:56:23.830Z"
				display="block"
				width="220px"
				margin="0px 0px 70px 0px"
				filter="saturate(300%)"
				srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11:45:26.787Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-3.jpg?v=2024-05-31T11%3A45%3A26.787Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Послуги оренди поля
					</Text>
					<Image
						src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12:56:23.830Z"
						display="block"
						width="150px"
						margin="0px 0px 15px 0px"
						filter="saturate(300%)"
						srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11:45:26.769Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-3.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Оренда спорядження
					</Text>
					<Image
						src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12:56:23.830Z"
						display="block"
						width="150px"
						margin="0px 0px 15px 0px"
						filter="saturate(300%)"
						srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11:45:26.770Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Послуги з організації заходів
					</Text>
					<Image
						src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12:56:23.830Z"
						display="block"
						width="150px"
						margin="0px 0px 15px 0px"
						filter="saturate(300%)"
						srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/free-png.ru-248.png?v=2023-05-06T12%3A56%3A23.830Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="80px 0 80px 0" quarkly-title="Schedule-7">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				display="grid"
				grid-gap="88px"
				lg-grid-gap="64px"
				sm-grid-gap="32px"
			/>
			<Box
				lg-width="100%"
				lg-align-items="center"
				lg-display="flex"
				lg-justify-content="center"
				lg-flex-direction="column"
				sm-align-items="flex-start"
			>
				<Text margin="0px 0px 8px 0px" font="--headline1" color="--light" sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Чому обирають нас?
				</Text>
			</Box>
			<Box
				display="grid"
				lg-width="100%"
				sm-flex-wrap="wrap"
				grid-template-columns="1fr 1fr"
				grid-gap="150px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px"
			>
				<Box>
					<Text margin="0px 0px 16px 0px" font="--headline2" color="--orange" lg-font="normal 900 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Чудові умови гри
					</Text>
				</Box>
				<Box display="grid" grid-gap="16px">
					<Text margin="0px 0px 0px 0px" font="--headline4" color="--light" lg-font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Наші поля щодня доглядаються, забезпечуючи ідеальні умови гри кожного разу, коли ви виходите на поле
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-width="100%"
				sm-flex-wrap="wrap"
				grid-template-columns="1fr 1fr"
				grid-gap="150px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px"
			>
				<Box>
					<Text margin="0px 0px 16px 0px" font="--headline2" color="--orange" lg-font="normal 900 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Гнучкі слоти для бронювання
					</Text>
				</Box>
				<Box display="grid" grid-gap="16px">
					<Text margin="0px 0px 0px 0px" font="--headline4" color="--light" lg-font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Ми пропонуємо різноманітні варіанти бронювання відповідно до вашого розкладу, гарантуючи, що ви можете грати, коли це найзручніше
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-width="100%"
				sm-flex-wrap="wrap"
				grid-template-columns="1fr 1fr"
				grid-gap="150px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px"
			>
				<Box>
					<Text margin="0px 0px 16px 0px" font="--headline2" color="--orange" lg-font="normal 900 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Конкурентні ціни
					</Text>
				</Box>
				<Box display="grid" grid-gap="16px">
					<Text margin="0px 0px 0px 0px" font="--headline4" color="--light" lg-font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Отримайте найкраще співвідношення ціни та якості завдяки нашим конкурентоспроможним ставкам і винятковій якості поля
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-width="100%"
				sm-flex-wrap="wrap"
				grid-template-columns="1fr 1fr"
				grid-gap="150px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px"
			>
				<Box>
					<Text margin="0px 0px 16px 0px" font="--headline2" color="--orange" lg-font="normal 900 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Доступне розташування
					</Text>
				</Box>
				<Box display="grid" grid-gap="16px">
					<Text margin="0px 0px 0px 0px" font="--headline4" color="--light" lg-font="normal 500 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
						Розташована в центрі з достатньою автостоянкою, FieldGoals Arena легко дістатися та чудово підходить для проведення місцевих і регіональних заходів
					</Text>
				</Box>
			</Box>
			<Hr
				min-width="100%"
				margin="0px 0px 0px 0px"
				border-color="--color-darkL2"
				padding="0px 0px 0px 0px"
				height="2px"
				border-width="2px 0px 0px 0px"
			/>
		</Section>
		<Components.NewFooter>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
		</Components.NewFooter>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});